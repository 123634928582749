<template>
	<div class="index-page">
		<NewHead />
		<banner :banner="slidePic" :slide="slide"></banner>
		<!-- <div class="index-banner">
			<swiper class="swiper wjpaper-banner" :options="swiperOptionThumbs" ref="swiperThumbs">
				<swiper-slide v-for="(item,index) in slidePic.length" :key='index'>
					<div class="banner-item" :style="{backgroundImage:'url('+slidePic[index]+')'}">
						<div class="container">
							<div class="banner-grid wow fadeInDown">
								<div class="ch">{{lang=='zh-cn'?slide.title:slide.title_en}}</div>
								<div class="en">{{lang=='zh-cn'?slide.titile_comment:slide.titile_comment_en}}</div>							
								<div class="banner-foot">
									<el-button v-if="slide.button1_txt" type="cyan" @click="buttonEevent1(slide.url1)">{{slide.button1_txt}}</el-button>
									<el-button v-if="slide.button2_txt" type="white" @click="buttonEevent1(slide.url2)">{{slide.button2_txt}}</el-button>
								</div>
							</div>
						</div>
					</div>
				</swiper-slide>
			</swiper>
		</div> -->
		<!-- <div class="index-bar wow fadeInDown">
			<div class="container">
				<div class="raty-left">
					<span>3000多个品牌的大小都爱我们! </span>
					<el-rate
					v-model="rateValue"
					disabled
					:colors="colors"
					text-color="#1AA8F0"
					score-template="{value}">
					</el-rate>
					<span> 4.6谷歌评论</span>
				</div>
				<div class="ic">
					<img src="../assets/image/index/ic1.png" alt="" />
					<img src="../assets/image/index/ic2.png" alt="" />
					<img src="../assets/image/index/ic3.png" alt="" />
					<img src="../assets/image/index/ic4.png" alt="" />
					<img src="../assets/image/index/ic5.png" alt="" />
				</div>
			</div>
		</div> -->
		<div class="page-wrapper">
			<div v-if="thispage.autoproduct">
				<div class="index-section">
					<div class="index-section index-style-b">
						<div class="container wow fadeInUp">
							<div class="section-head flex">
								<div class="flex_bd">
									<div class="h2">{{lang==='zh-cn'?pageName[0]:pageName[1] }}</div>
									<!-- <p>{{thispage.titile_comment}}</p> -->
								</div>
								<!-- <el-button v-if="lang==='zh-cn'" type="info" plain>浏览完整目录</el-button>
								<el-button v-else type="info" plain>Browse the full catalog</el-button> -->
							</div>
							<el-row :gutter="24" type="flex" class="pack-row">
								<el-col :xs="12" :sm="8" :md="8" :lg="6" v-for="(ite,idx) in product_list" :key="idx">
									<div class="pack-card hover-up"  @click="clickit(ite)">
										<div class="pack-photo scale">
											<img :src="ite.pic_address&&ite.pic_address.length>0?ite.pic_address.split(',')[0]:''" alt="" />
										</div>
										<div class="pack-grid">
											<div class="cell-title">{{ite.product_name}}</div>
											<div class="cell-desc">{{ite.specification}}</div>
										</div>
									</div>
								</el-col>
							</el-row>
						</div>
					</div>
				</div>
			</div>
			<Content v-else  :pagecontent="thispage.items"/>
			<div class="index-section">
                <div class="index-section index-style-b">
                    <div class="container wow fadeInUp">                        
                        <el-row :gutter="24" type="flex" class="pack-row">                            
                            <el-col :xs="12" :sm="8" :md="8" :lg="6">
                                <div v-if="lang==='zh-cn'" class="pack-more-card">
                                    <div class="p-title">在找别的东西吗?我们可以帮忙。</div>
                                    <el-button type="info" plain>请求定制报价</el-button>
                                </div>
                                <div v-else class="pack-more-card">
                                    <div class="p-title">Looking for something else? We can help.</div>
                                    <el-button type="info" plain>Request a custom quote</el-button>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </div>
		</div>
		<NewFoot />
		
	</div>
</template>

<script>
	import NewHead from '../components/Head.vue'
	import NewFoot from '../components/Foot.vue'
	import banner from '@/components/banner.vue'
	import Content from '../components/content.vue';
	import { mapState} from 'vuex';
	export default {
		name: 'Home',
		components: {
			NewHead,
			NewFoot,
			banner,
			Content
		},
		data() {
			return {
				product_cate: this.$route.query.pid,
				pid: this.$route.query.pid,
				cid: this.$route.query.cid,
				product_list: [],
				rateValue: 4.5,
				colors:["#1AA8F0","#1AA8F0","#1AA8F0","#1AA8F0","#1AA8F0"],						
				activeNames: [0],
				question:[
					{
						title:'你们最低订单数量是多少？',
						content:'我们的最低订单量(MOQ)取决于具体的产品线和定制要求。请与我们的产品专家联系,了解您感兴趣的产品的详细信息。你也可以看看我们的生活质量清单 在这里 .我们努力适应各种规格的订单,以满足客户的不同需求,所以不要犹豫 联系我们 !'
					},{
						title:'你们能生产处少于最低订单数量的产品吗？',
						content:'我们的最低订单量(MOQ)取决于具体的产品线和定制要求。请与我们的产品专家联系,了解您感兴趣的产品的详细信息。你也可以看看我们的生活质量清单 在这里 .我们努力适应各种规格的订单,以满足客户的不同需求,所以不要犹豫 联系我们 !'
					},{
						title:'你们的制作时间是多长？',
						content:'我们的最低订单量(MOQ)取决于具体的产品线和定制要求。请与我们的产品专家联系,了解您感兴趣的产品的详细信息。你也可以看看我们的生活质量清单 在这里 .我们努力适应各种规格的订单,以满足客户的不同需求,所以不要犹豫 联系我们 !'
					},{
						title:'你们的产品都可以回收吗？',
						content:'我们的最低订单量(MOQ)取决于具体的产品线和定制要求。请与我们的产品专家联系,了解您感兴趣的产品的详细信息。你也可以看看我们的生活质量清单 在这里 .我们努力适应各种规格的订单,以满足客户的不同需求,所以不要犹豫 联系我们 !'
					},{
						title:'在我的定制包装投入生产之前，我会收到一个样品吗？',
						content:'我们的最低订单量(MOQ)取决于具体的产品线和定制要求。请与我们的产品专家联系,了解您感兴趣的产品的详细信息。你也可以看看我们的生活质量清单 在这里 .我们努力适应各种规格的订单,以满足客户的不同需求,所以不要犹豫 联系我们 !'
					}
				],
				tavName:'a',
				banner: require('../assets/image/index/bannerx.jpg'),
				swiperOptionThumbs: {
					loop: true,
					autoplay: 4000,
					pagination: {
						el: '.swiper-pagination',
						clickable: true
					},
					navigation: {
						nextEl: '.swiper--next',
						prevEl: '.swiper--prev'
					}
				},
			};
		},
		computed:{
			...mapState({
				'lang':state=>state.app.currentLang,
				'webcontents':state=>state.app.webcontents,
				'current':state=>state.app.current,
				'en_id':state=>state.app.en_id
			}),
			thispage(){
				if(!this.current){
					var vid = this.webcontents.list.filter(item=>item.type == 'product')[0].id
					// this.setCurrent(vid)
					return this.webcontents.list.filter(item=>item.id == vid)[0]
				}else{
					return this.webcontents.list.filter(item=>item.id == this.current )[0]
				}		
				
			},
			slide(){
				let slide1 = null
				if(this.thispage){
					slide1 = this.thispage.items.filter(ite=>ite.item_type =='slideshow')
				}
				return (slide1 && slide1.length > 0) ?slide1[0]:'';
			},			
			slidePic(){
				// var slide = this.thispage.items.filter(ite=>ite.item_type =='slideshow')
				if(this.slide){
					return this.slide.pictures.split(',');
				}else{
					return [this.banner]
				}				
			},
			pageName(){
				if(this.thispage.children){
					let childs = this.thispage.children[0].list.filter(item=>item.cid===this.cid)

					if(childs.length>0){
						return [childs[0].ltitle,childs[0].ltitle_en]
					}
					else{
						return ''
					}
				}else{
					return [this.thispage.pagename_zh,this.thispage.pagename_en]
				}
			}
			// pageitems(){
			// 	var itemlist=this.thispage.items.filter(ite=>ite.item_type !='slideshow')
			// 	var items = []
			// 	var ob = {}
			// 	var pic = []
			// 	var title, ltitle, content,titile_comment
			// 	for (var it of itemlist){
			// 		if(it.item_type =='Three_column'){
			// 			ltitle = this.lang == 'en-US'?it.ltitle_en.split("`"):it.ltitle.split("`")
			// 			content = this.lang == 'en-US'?it.content_en.split("`"):it.content.split("`")
			// 		}else if(it.item_type !='picture' && it.item_type !='analysis'){
			// 			if(this.lang == 'en-US' &&it.content_en !='null'&&it.content_en){
			// 				content = JSON.parse(it.content_en).replace(/[\n\r]/g,'<p>')
			// 			}else if(it.content !='null'&&it.content){
			// 				content = JSON.parse(it.content).replace(/[\n\r]/g,'<p>')
			// 			}else{
			// 				content = ''
			// 			}
			// 		}else{
			// 			content=this.lang == 'en-US'?it.content_en:it.content
			// 		}
			// 		if(it.pictures){
			// 			pic=it.pictures.split(',')
			// 		}
			// 		title = this.lang == 'en-US'?it.title_en :it.title
			// 		titile_comment = this.lang == 'en-US'?it.titile_comment_en :it.titile_comment
			// 		ob = {title:title,titile_comment:titile_comment,ltitle:ltitle,pic:pic,item_type:it.item_type,content:content}
			// 		items.push(ob)
			// 	}
			// 	return items
			// },
		},
		watch: {
			$route: function (to, from) {
				if (to.query.cid !== from.query.cid) { 
					this.cid = to.query.cid
					this.getProductData()
				}
			}
		},
		created() {
			console.log(this.cid)
			console.log(this.lang)
			console.log(this.pid)
			console.log(JSON.stringify(this.thispage))
			if(!this.thispage){
				this.$router.push({ path:'/'})
			}
			if(this.thispage.autoproduct ){				
				this.getProductData()
			}
		},
		methods: {
			getProductData(){
				if(this.cid){
					let formData = {
						page_no: 1, 
						page_size: 30, 
						enterprise:this.en_id, 
						search_name: '',
						cate_id: this.cid,
					}
					this.$axios.post("https://jesway.cn/api/product/get_enter_product", formData).then(res=>{
						console.log("服务器返回", JSON.stringify(res))
						this.product_list = res.data.products
					})
				}
			},
			buttonEevent1(url){
				if(url.includes('http')){
					window.open(url, '_blank')
				}else{
					this.$router.push({ path:url})
				}
			},
			clickit(item){
				this.$router.push({ path: '/ProductDetail', query:{prod_id:item.id}})
			}
		},
		mounted() {
			new this.$wow.WOW({live: false}).init();
		}
	}
</script>

<style scoped>
</style>